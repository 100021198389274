import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import './nav.css'

const debounce = (func, delay) => {
  let debounceTimer
  return function() {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

function Nav() {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0)

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowWidth(window.innerWidth)
    }, 150)
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)    
  })

  if (windowWidth > 600) {
    return (
      <nav
        style={{
          minWidth: `25%`,
          display: `flex`,
          overflow: `hidden`,
          whiteSpace: `nowrap`,
          padding: `1.0875rem`,
        }}
      >      
        <Link
          to="/skills"
          className="headerLink"
        >
          Skills
        </Link>
        <Link
          to="/about"
          className="headerLink"
        >
          About
        </Link>
        <Link
          to="/contact"
          className="headerLink"
        >
          Contact
        </Link>
        
      </nav>
    )
  } else {
    return (
      <nav
      style={{
        overflow: `hidden`,
        whiteSpace: `nowrap`,
        padding: `1.45rem 1.0875rem`,
      }}
      >      
        <button aria-label="Dropdown navigation menu button" className="hamburger-button" onClick={ 
            () => {
              const linksContainer = document.getElementById('links_container')
              linksContainer.style.display === 'none' ? linksContainer.style.display = 'flex' : linksContainer.style.display = 'none'
            }
          }>
        </button>
        <div id="links_container" style={{ display: `none` }}>
          <Link
          to="/skills"
          className="headerLink"
          >
            Skills
          </Link>
          <Link
            to="/about"
            className="headerLink"
          >
            About
          </Link>
          <Link
            to="/contact"
            className="headerLink"
          >
            Contact
          </Link>
        </div>

      </nav>
    )
  }
}

export default Nav
